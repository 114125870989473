<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных склада
      </h4>
      <div class="alert-body">
        Не найден склад с этим идентификатором. Можете перейти в
        <b-link
          class="alert-link"
          :to="{ name: 'warehouses'}"
        >
          Список складов
        </b-link>
        и выбрать другой.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <b-row>
        <b-col>
          <h4>
            {{ item.name }}
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table-simple
            :striped="true"
            :bordered="false"
            :borderless="true"
            :outlined="false"
            :small="false"
            :hover="false"
            :dark="false"
            :fixed="false"
            :responsive="true"
            class="mt-1"
          >
            <b-tbody>
              <b-tr>
                <b-th>
                  ID
                </b-th>
                <b-td>
                  {{ item.id }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Контактная информация
                </b-th>
                <b-td>
                  <div style="white-space: pre-line;">
                    {{ item.contact_information || '-' }}
                  </div>
                </b-td>
              </b-tr>
              <b-tr v-if="item.working_schedule">
                <b-th>
                  График работы
                </b-th>
                <b-td>

                  <div
                    v-for="w_day in weekDays"
                    :key="w_day.key"
                  >
                    <small><strong>{{ w_day.label }}</strong></small>:
                    <small>
                      {{ item.working_schedule[w_day.key] }}
                    </small>
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col cols="12">
          <address-field
            :value="item.real_address"
            mode="view"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="$store.getters['workingMode/checkPermission']('manage_warehouses')"
        class="mt-2"
      >
        <b-col>
          <div class="d-flex flex-wrap">
            <b-button
              v-if="item.id"
              :to="{ name: 'warehouses.edit', params: { id: item.id } }"
              variant="primary"
            >
              Изменить
            </b-button>
            <b-button
              variant="outline-danger"
              class="ml-1"
              @click="deleteItem(item)"
            >
              Удалить
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BOverlay, BButton, BAlert, BLink, BBadge, BTabs, BTab,
  BFormGroup, BFormInput, BFormTextarea, BCardHeader, BCardTitle, BTable, BFormCheckbox,
  BTableSimple, BTbody, BTr, BTd, BTh, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import get from 'lodash/get'
import { getCurrentInstance, ref } from 'vue'
import {
  deleteWarehouse as deleteWarehouseApi,
  getWarehouse as getWarehouseApi,
} from '@/services/main-api/shop/warehouses'
import useCrudShow from '@/composables/useCrudShow'
import AddressField from '@/components/AddressField.vue'

export default {
  name: 'ShowItemView',
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BAlert,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BTh,
    BListGroup,
    BListGroupItem,
    AddressField,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)
    const weekDays = [
      { label: 'Понедельник', key: 'monday' },
      { label: 'Вторник', key: 'tuesday' },
      { label: 'Среда', key: 'wednesday' },
      { label: 'Четверг', key: 'thursday' },
      { label: 'Пятница', key: 'friday' },
      { label: 'Суббота', key: 'saturday' },
      { label: 'Воскресенье', key: 'sunday' },
    ]

    const getItem = async () => {
      try {
        const res = await getWarehouseApi({ id: props.id, includes: 'real_address' })
        instance.item = res.data.data
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    const {
      item,
      loading,
      deleteItem,
    } = useCrudShow({
      item: {
        id: null,
        name: '',
        contact_information: '',
        working_schedule: {
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
          sunday: '',
        },
        real_address: {},
      },
      getItem,
      deleteEntity: i => deleteWarehouseApi(i.id),
      getQuestionTextBeforeDeletion: i => `Склад ${i.name} будет удален`,
      getTextAfterDeletion: () => 'Склад был удален.',
      runAfterDeletion: () => {
        instance.$router.push({ name: 'warehouses' })
      },
    })

    return {
      item,
      loading,

      getItemError,
      deleteItem,

      weekDays,

      get,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
